import axios from '@axios'

export default function speakerService() {
  const getSpeakerBlocks = async (queryParams) => {
    const res = await axios.get('/events/blocks/by-id/speaker/now', {
      params: queryParams,
    })
    console.log(res)
    return res.data.response
  }

  return {
    getSpeakerBlocks,
  }
}
